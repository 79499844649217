export enum QuoteStatuses {
  DRAFT = "DRAFT",
  PENDING = "PENDING",
  FINALIZED = "FINALIZED",
}

export enum QuoteStatusFriendlyNames {
  DRAFT = "Draft",
  PENDING = "Policy Request",
  FINALIZED = "Converted",
}
