import {
  DEFAULT_SERVER_ERROR_DESCRIPTION,
  DEFAULT_SERVER_ERROR_TITLE,
} from "../constants/default-error-messages";

export class ServerError extends Error {
  title: string;
  messages: string[];

  constructor(
    properties:
      | {
          title: string;
          messages: string[];
        }
      | undefined,
  ) {
    const messages =
      properties?.messages && properties.messages.length > 0
        ? properties.messages
        : [DEFAULT_SERVER_ERROR_DESCRIPTION];
    super(messages.join("\n"));
    this.name = "ServerError";
    this.title = properties?.title || DEFAULT_SERVER_ERROR_TITLE;
    this.messages = messages;
  }
}
