import React from "react";

const IconCircleSuccess = ({ size = "40" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#459F36" />
      <path
        d="M12.5 21.25L17.5 26.25L28.75 15"
        stroke="white"
        strokeWidth="5"
        strokeLinecap="round"
      />
    </svg>
  );
};
export default IconCircleSuccess;
