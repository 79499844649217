import { useMutation } from "@tanstack/react-query";
import { showToastForError } from "../utils/show-toast-for-server-error";

export const useServerMutation = (mutationOptions: {
  mutationKey: string[];
  mutationFn: (variables: any) => Promise<any>;
}) => {
  const mutation = useMutation({
    ...mutationOptions,
    onSuccess: (data, variables, context) => {
      variables?.onSuccess?.(data);
    },
    onError: (error: unknown, variables, context) => {
      if (!variables.hideErrorToast) {
        showToastForError(error);
      }
      if (variables?.onError) {
        variables.onError();
      }
    },
  });

  return mutation;
};
