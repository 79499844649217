import * as Sentry from "@sentry/react";
import React from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

export const initSentry = () => {
  Sentry.init({
    dsn: "https://2ba2b620b00e6b7d6ff26ba8ac5c0860@o537383.ingest.us.sentry.io/4507532124684288",

    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "staging.reseller.harbor.getakko.com",
      "staging.client.harbor.getakko.com",
      "reseller.harbor.getakko.com",
      "client.harbor.getakko.com",
      `${process.env.REACT_APP_API}`,
    ],
    enabled: process.env.REACT_APP_ENVIRONMENT !== "development",
    environment: process.env.REACT_APP_ENVIRONMENT,
  });

  Sentry.setContext("build", {
    deployId: process.env.REACT_APP_NETLIFY_BUILD_ID,
    commitRef: process.env.REACT_APP_NETLIFY_COMMIT_REF,
  });
};
