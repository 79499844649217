import React from "react";
import { showToast } from "../components/toast";
import { ServerError } from "./server-error";
import {
  DEFAULT_ERROR_DESCRIPTION,
  DEFAULT_ERROR_TITLE,
} from "../constants/default-error-messages";
import { captureException } from "@sentry/react";

const ErrorDescription = (error: unknown) => {
  if (error instanceof ServerError) {
    const hasMultipleErrors = error.messages.length > 1;

    return (
      <ul>
        {error.messages.map((err, index) => (
          <li key={index}>
            {hasMultipleErrors ? `${index + 1}. ${err}` : err}
          </li>
        ))}
      </ul>
    );
  }

  return DEFAULT_ERROR_DESCRIPTION;
};

const getErrorTitle = (error: unknown) => {
  if (error instanceof ServerError) {
    return error.title;
  }

  return DEFAULT_ERROR_TITLE;
};

export const showToastForError = (error: unknown) => {
  // This is temporary. We will send all toast issues to sentry,
  // to have more details on which errors our users are encountering.
  captureException(error);

  showToast({
    title: getErrorTitle(error),
    description: ErrorDescription(error),
    status: "error",
  });
};
