import React from "react";
import ReactDOM from "react-dom/client";
import "./scss/index.scss";
import "react-toastify/dist/ReactToastify.css";
import reportWebVitals from "./reportWebVitals";

import { initTracking, posthog } from "./services/posthog";
import { initAmplify, Authenticator } from "./services/amplify";
import "@aws-amplify/ui-react/styles.css";
import ToastProvider from "./components/toast";
import { BrowserRouter } from "react-router-dom";
import LoadingModal from "./components/loading-modal";
import { initSentry } from "./services/sentry";
import { PostHogProvider } from "posthog-js/react";

import { Provider } from "jotai/react";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { showToastForError } from "./utils/show-toast-for-server-error";

initTracking();
initAmplify();
initSentry();

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      showToastForError(error);
    },
  }),
});

function importBuildTarget() {
  if (process.env.REACT_APP_IS_RESELLER_APP) {
    return import("./ResellerApp");
  } else if (process.env.REACT_APP_IS_ORGANIZATION_APP) {
    return import("./OrganizationApp");
  } else {
    return Promise.reject(new Error("No such application"));
  }
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

importBuildTarget().then(({ default: App }) =>
  root.render(
    <React.StrictMode>
      <Authenticator variation="modal" hideSignUp>
        <PostHogProvider client={posthog}>
          <QueryClientProvider client={queryClient}>
            <Provider>
              <>
                <LoadingModal />
                <ToastProvider />
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </>
            </Provider>
          </QueryClientProvider>
        </PostHogProvider>
      </Authenticator>
    </React.StrictMode>,
  ),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
