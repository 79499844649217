import { LossTypesCategories } from "../types/loss-types-categories";

export const lossTypesCategoriesMap: Record<string, LossTypesCategories> = {
  Malfunctions: "Mechanical Breakdown",
  "Mechanical Breakdown": "Mechanical Breakdown",
  "Accidental Damage": "Accidental Damage",
  "Theft and Vandalism": "Theft",
  Theft: "Theft",
  Loss: "Loss",
};
