import posthog from "posthog-js";
import { PostHogEvent } from "../constants/posthog";
import { applicationIdentifier } from "../utils/application-identifier";

const currentApplication = applicationIdentifier();

const isDevelopment = process.env.NODE_ENV === "development";

export const initTracking = function (): void {
  if (!currentApplication) return;

  if (isDevelopment) {
    posthog.init(process.env.REACT_APP_POSTHOG_KEY as string, {
      api_host: process.env.REACT_APP_POSTHOG_HOST,
    });
  } else {
    posthog.init(process.env.REACT_APP_POSTHOG_KEY as string, {
      api_host: `${window.location.origin}/ingest/`,
      ui_host: "https://us.posthog.com",
    });
  }

  posthog.register({
    application: currentApplication,
    deployId: process.env.REACT_APP_NETLIFY_BUILD_ID,
    commitRef: process.env.REACT_APP_NETLIFY_COMMIT_REF,
  });
};

export const trackEvent = function (
  event: PostHogEvent,
  payload: Record<string, unknown>,
): void {
  posthog.capture(event, payload);
};

export const identifyUser = function ({
  userId,
  email,
  resellerName,
  organizationName,
  roles,
}: {
  userId: string;
  email: string;
  resellerName?: string;
  organizationName?: string;
  roles: string[];
}): void {
  if (!currentApplication) return;

  posthog.identify(`${currentApplication}_user_${userId}`, {
    application: currentApplication,
    email,
    roles: roles,
    ...(resellerName && { reseller_name: resellerName }),
    ...(organizationName && { organization_name: organizationName }),
  });
};

export const reset = function (): void {
  posthog.reset();
};

export { posthog };
