import { atom } from "jotai";
import { Quote } from "../types/quotes";
import { getAccessToken } from "../services/amplify";

import {
  updateItemsInShoppingCartAtom,
  shoppingCartAtomV2,
} from "./shopping-cart-v2";
import { atomWithRefresh, loadable } from "jotai/utils";
import { Pagination } from "../types/pagination";
import { QuoteStatuses } from "../constants/quotes";

import { atomEffect } from "jotai-effect";
import { PAGE_SIZE } from "./pagination";
import { showToast } from "../components/toast";
import { trackEvent } from "../services/posthog";
import { PostHogEvent } from "../constants/posthog";
import { uniqueId } from "lodash";
import { ServerError } from "../utils/server-error";
import { showToastForError } from "../utils/show-toast-for-server-error";
import { formatQuotes } from "./new-quotes";

export const loadingGetQuotesAtom = atom(false);

const getQuotesEffect = atomEffect((get, set) => {
  if (get(loadableGetQuotesAtom).state === "loading") {
    set(loadingGetQuotesAtom, true);
  } else {
    set(loadingGetQuotesAtom, false);
  }
});

export const createQuoteEffect = atomEffect((get, set) => {
  if (get(loadableAsyncRequestResponseCreatedQuoteAtom).state === "loading") {
    set(loadingGetQuotesAtom, true);
  } else {
    set(loadingGetQuotesAtom, false);
  }

  const loadable = get(loadableAsyncRequestResponseCreatedQuoteAtom);
  if (loadable.state === "hasData" && loadable.data) {
    set(getQuotesAtom);
  }
});

export const loadingQuotesPendingForReviewAtom = atom(false);

const quotesPendingForReviewAtom = atomEffect((get, set) => {
  if (get(loadableQuotesPendingForReviewAtom).state === "loading") {
    set(loadingQuotesPendingForReviewAtom, true);
  } else {
    set(loadingQuotesPendingForReviewAtom, false);
  }
});

export const currentQuoteAtom = atom<Quote>({
  id: "",
  name: `Quote #${Math.floor(Math.random() * 1000000)}`,
  tags: [],
  dateCreated: "",
  products: [],
  client: null,
  author: null,
  status: QuoteStatuses.DRAFT,
  prices: {
    cost: 0,
    msrp: 0,
    clientPrice: 0,
  },
});

export const currentStepIndexAtom = atom(0);

export const updateCurrentQuoteAtom = atom(null, (get, set, update: Quote) => {
  set(currentQuoteAtom, update);
});

export const resetCurrentQuoteAtom = atom(null, (get, set) => {
  set(currentQuoteAtom, {
    id: "",
    name: `Quote #${Math.floor(Math.random() * 1000000)}`,
    tags: [],
    dateCreated: "",
    products: [],
    client: null,
    author: null,
    status: QuoteStatuses.DRAFT,
    prices: {
      cost: 0,
      msrp: 0,
      clientPrice: 0,
    },
  });
  set(currentStepIndexAtom, 0);
});

const BASE_URL = `${process.env.REACT_APP_API_PRODUCTS}/internal/v3/quotes/`;

export const currentQuotePageAtom = atom<string>(BASE_URL);

export const goToPaginationPageAtom = atom(null, (get, set, page: number) => {
  const url = `${BASE_URL}?page=${page}&page_size=${PAGE_SIZE}`;
  set(currentQuotePageAtom, url);
});

const parseFilters = (url: string, filters: string[], search: string) => {
  const queryParams = new URLSearchParams();

  if (search) {
    queryParams.set("search", search);
  }

  if (filters.length) {
    filters.forEach((filter) => {
      queryParams.append("statuses", filter);
    });
  }
  if (queryParams.toString() === "") {
    return "";
  }

  if (url.includes("?")) {
    return `&${queryParams}`;
  } else {
    return `?${queryParams}`;
  }
};

export const quotePaginationAtom = atom<Pagination<null>>((get) => {
  get(getQuotesEffect);
  const getQuoteLoadable = get(loadableGetQuotesAtom);

  if (getQuoteLoadable.state !== "hasData") {
    return {
      metadata: {
        total: NaN,
        total_pages: NaN,
        first_page: NaN,
        last_page: NaN,
        page: 1,
        previous_page: NaN,
        next_page: NaN,
      },
      links: {
        self: `${process.env.REACT_APP_API_PRODUCTS}/internal/v3/quotes/`,
        first: "",
        last: "",
        prev: "",
        next: "",
      },
      data: null,
    };
  } else {
    const paginationLinks = getQuoteLoadable.data.links;
    const metadata = getQuoteLoadable.data.metadata;

    return {
      metadata: {
        total: metadata.total,
        total_pages: metadata.total_pages,
        first_page: metadata.first_page,
        last_page: metadata.last_page,
        page: metadata.page,
        previous_page: NaN,
        next_page: NaN,
      },
      links: {
        self: paginationLinks.self as string,
        first: paginationLinks.first,
        last: paginationLinks.last,
        prev: paginationLinks.prev,
        next: paginationLinks.next,
      },
      data: null,
    };
  }
});

export const asyncRequestResponseCreatedQuoteAtom = atom<Promise<any> | null>(
  null,
);

export const loadableAsyncRequestResponseCreatedQuoteAtom = loadable(
  asyncRequestResponseCreatedQuoteAtom,
);

export const createQuoteAtom = atom(null, async (get, set) => {
  const currentQuote = get(currentQuoteAtom);
  const shoppingCart = get(shoppingCartAtomV2);
  const url = `${process.env.REACT_APP_API_PRODUCTS}/internal/v3/quotes/`;
  const accessToken = await getAccessToken();

  const payload: { [key: string]: any } = {
    name: currentQuote.name,
  };

  if (currentQuote.client?.id) {
    payload.client_information_id = currentQuote.client?.id;
  }

  payload.quote_details = shoppingCart.map((item, index) => ({
    product_price_id: item.productPriceId,
    quantity: item.quantity,
    client_price: item.sellingPrice,
    display_order: index,
  }));

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(payload),
  });

  if (!response?.ok) {
    const data = await response.json();
    throw new ServerError(data.errors);
  }

  trackEvent(PostHogEvent.QUOTE_COMPLETE, {
    contract_line_item_count: payload.quote_details?.length,
  });
  showToast({
    title: "Quote Created",
    description: "Quote has been created successfully",
    status: "success",
  });
  set(asyncRequestResponseCreatedQuoteAtom, response.json());
});

export const updateQuoteAtom = atom(null, async (get, set) => {
  const currentQuote = get(currentQuoteAtom);

  const accessToken = await getAccessToken();

  const shoppingCart = get(shoppingCartAtomV2);

  const updateQuotesPayload = {
    name: currentQuote.name,
    client_information_id: currentQuote.client?.id,
    status: currentQuote.status,
    quote_details: shoppingCart.map((item, index) => ({
      product_price_id: item.productPriceId,
      quantity: item.quantity,
      display_order: index,
      client_price: item.sellingPrice,
    })),
  };

  if (currentQuote.status === QuoteStatuses.DRAFT) {
    delete updateQuotesPayload.status;
  }

  const response = await fetch(
    `${process.env.REACT_APP_API_PRODUCTS}/internal/v3/quotes/${currentQuote.id}/`,
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(updateQuotesPayload),
    },
  );

  if (!response?.ok) {
    const data = await response.json();
    throw new ServerError(data.errors);
  }

  trackEvent(PostHogEvent.QUOTE_UPDATED, {
    contract_line_item_count: updateQuotesPayload.quote_details?.length,
  });
  set(getQuotesAtom);
  showToast({
    title: "Quote Updated",
    description: "Quote has been updated successfully",
    status: "success",
  });
});

export const quotesAtom = atom<Quote[]>((get) => {
  get(getQuotesEffect);
  const getQuoteLoadable = get(loadableGetQuotesAtom);
  if (getQuoteLoadable.state === "hasError") {
    showToastForError(getQuoteLoadable.error);
  }
  if (
    getQuoteLoadable.state === "loading" ||
    getQuoteLoadable.state === "hasError"
  ) {
    return [];
  }
  const rawQuotes = getQuoteLoadable.data?.data || [];
  return formatQuotes(rawQuotes);
});

export const getQuotesAtom = atomWithRefresh<Promise<any>>(async (get) => {
  const url: string = get(currentQuotePageAtom);

  if (!url.includes("page")) {
    return {
      data: [],
      metadata: {
        page: 1,
        total: 0,
      },
      links: {
        self: url,
        first: "",
        last: "",
        prev: "",
        next: "",
      },
    };
  }

  const filters = parseFilters(
    url,
    get(selectedFiltersAtom),
    get(stringFilterAtom),
  );

  const accessToken = await getAccessToken();

  const response = await fetch(`${url}${filters}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const data = await response.json();

  if (!response?.ok) {
    throw new ServerError(data.errors);
  }

  return data;
});

export const loadableGetQuotesAtom = loadable(getQuotesAtom);

export const selectedFiltersAtom = atom<string[]>([]);

export const stringFilterAtom = atom<string>("");

export const convertQuoteToPolicyAtom = atom(
  null,
  async (_get, _set, quoteId: string) => {
    const url = `${process.env.REACT_APP_API_PRODUCTS}/internal/v3/convert-quote/${quoteId}/`;
    const accessToken = await getAccessToken();

    return await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
);

export const convertQuoteStateAtom = atom(
  null,
  async (
    _get,
    _set,
    quoteId: string,
    quote: {
      name: string;
      status: QuoteStatuses.DRAFT | QuoteStatuses.PENDING;
      client_information_id: string;
    },
  ) => {
    const url = `${process.env.REACT_APP_API_PRODUCTS}/internal/v3/quotes/${quoteId}/`;
    const accessToken = await getAccessToken();

    let payload;

    if (quote.status === QuoteStatuses.DRAFT) {
      payload = {
        status: quote.status,
      };
    } else {
      payload = quote;
    }

    return await fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(payload),
    });
  },
);

export const getQuotesPendingForReviewAtom = atomWithRefresh(async (_get) => {
  const url = `${BASE_URL}?page=1&page_size=1&statuses=${QuoteStatuses.PENDING}`;

  const accessToken = await getAccessToken();

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (!response?.ok) {
    const data = await response.json();
    throw new ServerError(data.errors);
  }

  return await response.json();
});

export const loadableQuotesPendingForReviewAtom = loadable(
  getQuotesPendingForReviewAtom,
);

export const hasQuotesPendingForReviewAtom = atom<boolean>((get) => {
  get(quotesPendingForReviewAtom);
  const loadable = get(loadableQuotesPendingForReviewAtom);

  if (loadable.state === "loading" || loadable.state === "hasError") {
    return false;
  }

  return loadable.data.data.length > 0;
});

export const loadableConvertQuoteToPolicyAtom = loadable(
  convertQuoteToPolicyAtom,
);

export const setQuoteToEditAtom = atom(
  null,
  (
    get,
    set,
    args: {
      quote: Quote;
      step?: number;
    },
  ) => {
    set(currentStepIndexAtom, args.step || 0);
    set(updateCurrentQuoteAtom, args.quote);
    set(updateItemsInShoppingCartAtom, {
      items: args.quote.products
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .map((product) => ({
          key: uniqueId(),
          quantity: product.seats,
          sellingPrice: product.clientPrice,
          deviceType: product.deviceType,
          subDeviceType: product.subDeviceType,
          productPriceId: product.productPriceId,
          coverageTypes: product.coverageTypes,
          interval: product.interval,
          serviceFee: product.serviceFee,
          msrp: product.msrp,
          value: product.cost,
          deviceValue: product.itemRetailValue,
          isSellingPriceDirty: true,
        })),
    });
  },
);
export const hasFiltersAppliedQuotesAtom = atom<boolean>((get) => {
  const filters = get(selectedFiltersAtom);
  const search = get(stringFilterAtom);
  return filters.length > 0 || search !== "";
});
