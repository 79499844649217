import { Amplify } from "aws-amplify";
import {
  Authenticator as AmplifyAuthenticator,
  useAuthenticator as useAmplifyAutenticator,
  translations,
} from "@aws-amplify/ui-react";
import { signOut as amplifySignOut, fetchAuthSession } from "aws-amplify/auth";
import { I18n } from "aws-amplify/utils";
import { reset } from "./posthog";

export const initAmplify = () => {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: process.env.REACT_APP_AWS_COGNITO_POOL_ID as string,
        userPoolClientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID as string,
        identityPoolId: "",
        signUpVerificationMethod: "code",
        loginWith: {
          oauth: {
            domain: process.env.REACT_APP_AWS_COGNITO_DOMAIN as string,
            scopes: ["openid", "email", "phone", "profile"],
            providers: ["Google"],
            responseType: "code",
            redirectSignIn: [window.location.origin],
            redirectSignOut: [window.location.origin],
          },
        },
        passwordFormat: {
          minLength: 8,
          requireLowercase: true,
          requireUppercase: true,
          requireNumbers: true,
          requireSpecialCharacters: true,
        },
      },
    },
  });
};

export const signOut = () => {
  amplifySignOut();
  reset();
};

export const getAccessToken = async () => {
  try {
    const { accessToken } = (await fetchAuthSession()).tokens ?? {};

    return accessToken?.toString();
  } catch (err) {
    console.log(err);
  }
};

I18n.putVocabularies(translations);
I18n.putVocabularies({
  en: {
    "1 validation error detected: Value null at 'attributeName' failed to satisfy constraint: Member must not be null":
      "Please select a verification method",
  },
});

export const Authenticator = AmplifyAuthenticator;

export const useAuthenticator = useAmplifyAutenticator;
